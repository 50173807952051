import UiDropdown from './UiDropdown'
import LanguageList from './LanguageList'
import UiHovered from './UiHovered'
import UiCountDown from './UiCountdown'
import RequestComponent from './RequestComponent'
import FaqComponent from './FaqComponent'
import SetLocation from './SetLocation'
import UiClickOutside from './UiClickOutside'

const UiSlider = () => import('./UiSlider')
const UiLightbox = () => import('./UiLightbox')
const BonusInfo = () => import('./BonusInfo')
const RatingInfo = () => import('./RatingInfo')
// const UiGarland = () => import('./Garland')

export default function install(Vue) {
  Vue.component(UiDropdown.name, UiDropdown)
  Vue.component(LanguageList.name, LanguageList)
  Vue.component(UiHovered.name, UiHovered)
  Vue.component('UiSlider', UiSlider)
  Vue.component(UiCountDown.name, UiCountDown)
  Vue.component('UiLightbox', UiLightbox)
  Vue.component(RequestComponent.name, RequestComponent)
  Vue.component(FaqComponent.name, FaqComponent)
  Vue.component(SetLocation.name, SetLocation)
  Vue.component(UiClickOutside.name, UiClickOutside)
  Vue.component('BonusInfo', BonusInfo)
  Vue.component('rating-info', RatingInfo)
  // Vue.component('UiGarland', UiGarland)
}
